:root {
  --background-primary-color: #F5F6F7;
  --background-secondary-color: #FFEBF0;
  --background-tertiary-color: #FFFFFF;
  --border-error-color: #C52D2F;
  --border-info-color: #1663AC;
  --border-membership-primary-color: #DCB5C4;
  --border-membership-secondary-color: #9084B7;
  --border-membership-tertiary-color: #4E8F98;
  --border-primary-color: #1C1F24;
  --border-secondary-color: #A2A2A9;
  --border-success-color: #51722C;
  --border-tertiary-color: #D9DADD;
  --border-warning-color: #95570D;
  --icon-error-color: #C52D2F;
  --icon-primary-color: #1C1F24;
  --icon-brand-primary-color: #DA291C;
  --icon-secondary-color: #686870;
  --icon-success-color: #51722C;
  --icon-surface-primary-color: #FFFFFF;
  --icon-tertiary-color: #A2A2A9;
  --icon-warning-color: #95570D;
  --icon-info-color: #1663AC;
  --shape-brand-accent01-dark-color: #DA291C;
  --shape-brand-accent01-light-color: #FB7DAC;
  --shape-brand-accent02-dark-color: #493E71;
  --shape-brand-accent02-light-color: #D8CBFE;
  --shape-brand-accent03-dark-color: #0A6B74;
  --shape-brand-accent03-light-color: #4E8F98;
  --surface-error-color: #FEE1E1;
  --surface-info-color: #BEE3F8;
  --surface-membership-primary-color: #FFEBF0;
  --surface-membership-secondary-color: #D8CBFE;
  --surface-membership-tertiary-color: #D0EEEC;
  --surface-primary-color: #1C1F24;
  --surface-secondary-color: #D9DADD;
  --surface-success-color: #DEEDD3;
  --surface-tertiary-color: #FFFFFF;
  --surface-warning-color: #FAE6B3;
  --text-brand-primary-color: #DA291C;
  --text-error-color: #C52D2F;
  --text-info-color: #1663AC;
  --text-primary-color: #1C1F24;
  --text-secondary-color: #686870;
  --text-success-color: #51722C;
  --text-surface-primary-color: #FFFFFF;
  --text-warning-color: #95570D;
  --wayfinding-path-color: #1663AC;
  --wayfinding-path-covered-color: #686870;
  --wayfinding-waypoint-color: #1663AC;
  --wayfinding-waypoint-border-color: #FFFFFF;
  --surface-brand-primary-color: #FFF6ED; /* Cream */
  --surface-brand-secondary-color: #FAE3D0; /* Linen */
  --surface-primary-hover-color: #686870; /* Quartz */
  --shape-brand-primary-color: #DA291C; /* Westfield Red */
  --shape-brand-secondary-color: #FFF6ED; /* Cream */
  --store-selected-fill-color: #F7E4BB;
  --store-selected-border-color: #C6B591;
}
