@font-face {
  font-family: 'Cera PRO';
  src: url('CeraPRO-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cera PRO';
  src: url('CeraPRO-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
